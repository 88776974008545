/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Validator from 'lib/validator';

function SEO(props) {
  

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
          }
        }
      }
    `
  )

  if (props) {
    const pageImagePath = (str) => {
      if (Validator.isWebPath(str)) {
        return 'https:' + str
      } else {
        return site.siteMetadata.siteUrl + str
      }
    }

    let description = props.data && props.data.description && props.data.description.description
    let title = props.data && props.data.pageTitle
    let pageImage = props.data && props.data.pageImage
    let noindex = props.data && props.data.noIndex
    let lang = 'en'

    const metaDescription = description || site.siteMetadata.description
    const metaTitle = `${title ? title + ' - ' : ''}Carbon Pizza Ovens`
    
    const metaPageImage = pageImagePath(pageImage && pageImage.gatsbyImageData && pageImage.gatsbyImageData.images && pageImage.gatsbyImageData.images.fallback && pageImage.gatsbyImageData.images.fallback.src);
    const imageHeight = pageImage && pageImage.gatsbyImageData.height;
    const imageWidth = pageImage && pageImage.gatsbyImageData.width;

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        link={[
          {rel: 'icon', type: 'image/jpg', href: '/favicon.jpg'}
        ]}
        title={metaTitle}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: 'twitter:image',
            content: metaPageImage
          },
          { 
            name: 'og:image', 
            content: metaPageImage
          },
          {
            name: 'og:image:height',
            content: imageHeight
          },
          {
            name: 'og:image:width',
            content: imageWidth
          },
          {
            name: 'robots',
            content: noindex ? "noindex, nofollow" : "index, follow"
          }
        ].concat([])}
      />
    )
  } else {
    return
  }
}

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }


export const seoFragment = graphql`
  fragment SEOFields on ContentfulSeo {
    __typename

    id
    contentful_id
    pageTitle
    noFollow
    pageImage {
      gatsbyImageData(height: 500, width: 500)
    }
    description {
      description
    }

  }
`

export default SEO
